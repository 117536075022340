<template>
  <div class="container">
    <!-- 头部导航 -->
    <div class="home-container">
      <img class="home-modules" src="../assets/images/cq.jpg" alt="">
      <div class="homenav-container">
        <el-menu mode="horizontal" class="top-menu">
          <el-menu-item index="1">
            <div class="menu-item-content">首页</div>
          </el-menu-item>
          <el-menu-item index="2">
            <div class="menu-item-content">关于驰清</div>
          </el-menu-item>
          <el-menu-item index="3">
            <div class="menu-item-content">产品中心</div>
          </el-menu-item>
          <el-menu-item index="4">
            <div class="menu-item-content">解决方案</div>
          </el-menu-item>
          <el-menu-item index="5">
            <div class="menu-item-content">联系我们</div>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="slideshow">
      <el-carousel :interval="3000" arrow="never" height="600px">
        <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
          <img :src="item.imageUrl" alt="Carousel Image" style="width: 100%; height: 100%;"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 解决方案1 -->
    <div class="solution-fanan">
      <div class="solution-babel">
        <img class="solution-lanindex" src="../assets/images/jeijue.png" alt="">
        <div class="solution-wenzi">
          <p>通过提供高效,可持续和环保的能源解决方案,</p>
          <p>致力于推动全球能源转型</p>
        </div>
        <div class="solution-reqtre">
         <div>
          <div class="solution-asspng image-container" @mouseover="toggleImage('1')"></div>
          <img v-if="imgActivited =='1'" src="../assets/images/huadedong1.png" alt="图片">
          <div class="solution-bajei image-container" @mouseover="toggleImage('2')"></div>
          <img v-if="imgActivited =='2'" src="../assets/images/huadedong2.png" alt="图片">
          <div class="solution-ponen image-container" @mouseover="toggleImage('3')"></div>
          <img v-if="imgActivited =='3'" src="../assets/images/huadedong3.png" alt="图片">
          <div class="solution-rouner image-container" @mouseover="toggleImage('4')"></div>
          <img v-if="imgActivited =='4'" src="../assets/images/huadedong4.png" alt="图片">
         </div>
        </div>
        <div class="solutionconlock" v-if="imgActivited =='1'">
          <div class="conlock-login">
            <h2>充电场站综合服务</h2>
            <p>科技赋能，为企事业单位和充电运营商，提供多元化、多场景的充换
              <br>电服务产品及解决方案，助力客户实现智能化、高效化的充电服务，
              <br>促进电动交通的发展和可持续能源的利用</p>
              <img class="conlock-ribje" src="../assets/images/fuwu1.jpg" alt="">
              <div class="circle-buttons">
                  <div :class="['circle', 'el-icon-arrow-left', { 'active': activeIndex === 0 }]" @click="prev"></div>
                  <div :class="['circle', 'el-icon-arrow-right', { 'active': activeIndex === 1 }]" @click="next"></div>
              </div>
          </div>
         </div>
         <div class="solutionconlock" v-if="imgActivited =='2'">
          <div class="conlock-login">
            <h2>分布式光伏及储能方案</h2>
            <p>绿色能源变革推动者，致力于光储充放新能源微网解决方案的开发
              <br>与提供。通过整合太阳能发电、储能以及充放电技术，建立灵活、可靠
              <br>的微网系统，实现对清洁能源的高效利用和储存</p>
              <img class="conlock-ribje" src="../assets/images/fuwu2.jpg" alt="">
              <div class="circle-buttons">
                  <div :class="['circle', 'el-icon-arrow-left', { 'active': activeIndex === 0 }]" @click="prev"></div>
                  <div :class="['circle', 'el-icon-arrow-right', { 'active': activeIndex === 1 }]" @click="next"></div>
              </div>
          </div>
         </div>
         <div class="solutionconlock" v-if="imgActivited =='3'">
          <div class="conlock-login">
            <h2>智慧能源</h2>
            <p>针对新能源为主的未来新型电力系统，为低碳智慧的城市新型
              <br>新能源网服务，助力政府和企业双碳转型</p>
              <img class="conlock-ribje" src="../assets/images/fuwu3.jpg" alt="">
              <div class="circle-buttons">
                  <div :class="['circle', 'el-icon-arrow-left', { 'active': activeIndex === 0 }]" @click="prev"></div>
                  <div :class="['circle', 'el-icon-arrow-right', { 'active': activeIndex === 1 }]" @click="next"></div>
              </div>
          </div>
         </div>
         <div class="solutionconlock" v-if="imgActivited =='4'">
          <div class="conlock-login">
            <h2>低碳园区</h2>
            <p>通过数字孪生技术，搭建三端的园区数字化碳中和服务平台，向上帮
              <br>助园区管理，向下为企业提供节能降碳等双碳综合服务， 形成上下
              <br>联动、管服一体的园区双碳创新模式</p>
              <img class="conlock-ribje" src="../assets/images/fuwu4.jpg" alt="">
              <div class="circle-buttons">
                  <div :class="['circle', 'el-icon-arrow-left', { 'active': activeIndex === 0 }]" @click="prev"></div>
                  <div :class="['circle', 'el-icon-arrow-right', { 'active': activeIndex === 1 }]" @click="next"></div>
              </div>
          </div>
         </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="bugythio-fsedeo">
      <div class="bugythio-bodetow">
        <img class="bugythio-index" src="../assets/images/guanyu.png" alt="">
        <img class="bugythio-index" src="../assets/images/women.png" alt="">
        <div class="bugythio-wenzi">
          <p>驰清能源科技（无锡）有限公司是一家专注于新能源领域的科技公司。</p>
          <p>我们致力于开发、生产和销售清洁能源技术和产品，包括太阳能、风能等可再生能源的利用和转换。公司的主要
            <br>业务涵盖太阳能光伏发电系统、风力发电系统、能源储存技术、能源管理系统等领域。通过提供高效、可持续和
            <br>环保的能源解决方案，驰清能源科技致力于推动全球能源转型，减少对传统化石能源的依赖，以实现可持续发展
            <br>的目标。</p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <el-button type="text" class="footer-dugstr">首页</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="text" class="footer-dugstr">产品中心</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="text" class="footer-dugstr">解决方案</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="text" class="footer-dugstr">关于我们</el-button>
    </div>

    <div style="width: 100%; text-align: center" v-show="greatrunenergyShow">
      <p class="container" style="border-top: 1px solid #666; margin: 20px auto 0 auto;color: #666;line-height: 50px;">
        Copyright©2023~2024&nbsp;greatrunenergy.com 无锡皓润新能源科技有限公司 版权所有
      </p>
      <div style="width: 100%; text-align: center; margin: 0 auto 20px auto">
        <img src="../assets/images/beian_gov.png" height="20" width="20"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021302001153"
           style="margin: 0px 0px 0px 5px;color:#939393;text-decoration:none;height:20px;line-height:20px;">
            苏公网安备32021302001153号
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn/"
           style="margin: 0px 0px 0px 15px;color:#939393;text-decoration:none;height:20px;line-height:20px;">
            苏ICP备2024060603号-1
        </a>
      </div>
    </div>

    <div style="width: 100%; text-align: center" v-show="lowrunenergyShow">
      <p class="container" style="border-top: 1px solid #666; margin: 20px auto 0 auto;color: #666;line-height: 50px;">
          Copyright©2023~2024&nbsp;lowrunenergy.com 无锡皓润新能源科技有限公司 版权所有
      </p>
      <div style="width: 100%; text-align: center; margin: 0 auto 20px auto">
        <img src="../assets/images/beian_gov.png" height="20" width="20"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021302001154"
           style="margin: 0px 0px 0px 5px;color:#939393;text-decoration:none;height:20px;line-height:20px;">
            苏公网安备32021302001154号
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn/"
           style="margin: 0px 0px 0px 15px;color:#939393;text-decoration:none;height:20px;line-height:20px;">
            苏ICP备2024060603号-2
        </a>
      </div>
    </div>

    <div class="width: 100%; text-align: center" v-show="cqnewenergyShow">
      <p class="container" style="border-top: 1px solid #666; margin: 20px auto 0 auto;color: #666;line-height: 50px;">
        Copyright©2023~2024&nbsp;<span>cqnewenergy.com 驰清能源科技（无锡）有限公司 版权所有</span>
      </p>
      <div style="width: 100%; text-align: center; margin: 0 auto 20px auto">
        <img src="../assets/images/beian_gov.png" height="20" width="20"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021102001608"
           style="margin: 0px 0px 0px 5px;color:#939393;text-decoration:none;height:20px;line-height:20px;">
          苏公网安备32021102001608号
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn/"
           style="margin: 0px 0px 0px 15px;color:#939393;text-decoration:none;height:20px;line-height:20px;">
          苏ICP备2023048133号
        </a>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name:"home",
  data() {
    return {
      // activeble:0,
      isImageVisible1: true,
      isImageVisible2: false,
      isImageVisible3: false,
      isImageVisible4: false,
      imgActivited: '1',
      current:0,
      activeIndex: 0,
      carouselItems: [
        { imageUrl: require('../assets/images/banner1.jpg') }, 
        { imageUrl: require('../assets/images/banner2.jpg') },
        { imageUrl: require('../assets/images/banner3.jpg') },
      ],
      currentIndex: 0,
      greatrunenergyShow: false,
      lowrunenergyShow: false,
      cqnewenergyShow: false,
    };
  },
  created() {
      if (window.location.host.indexOf('greatrunenergy') > -1) {
          this.greatrunenergyShow = true;
      }
      if (window.location.host.indexOf('lowrunenergy') > -1) {
          this.lowrunenergyShow = true;
      }
      if (window.location.host.indexOf('cqnewenergy') > -1) {
        this.cqnewenergyShow = true;
      }
  },
  mounted() {

  },
  methods: {
    goToImage(prevIndex) {
      // 隐藏当前内容块
      this['isImageVisible' + (this.activeIndex + 1)] = false;
      // 切换到下一个内容块
      this.activeIndex = prevIndex;
      // 显示新的内容块
      this['isImageVisible' + (this.activeIndex + 1)] = true;
    },
    scrollLeft() {
      const container = document.querySelector('.image-container');
      container.scrollLeft -= 100; // 负数表示向左滚动
    },
    scrollRight() {
      const container = document.querySelector('.image-container');
      container.scrollLeft += 100; // 正数表示向右滚动
    },
    prev(){
      if(this.imgActivited == '1'){
        this.imgActivited = '4'
      } else {
        this.imgActivited = `${+this.imgActivited - 1}`
      }

    },
    next(){
      if(this.imgActivited == '4'){
        this.imgActivited = '1'
      } else {
        this.imgActivited = `${+this.imgActivited + 1}`
      }
    },
    toggleImage(clickedElement) {
      this.imgActivited = clickedElement + ''
    },
    goToSlide(index) {
      this.currentIndex = index;
    },
    handleClick(index){
      this.current = index;
    }

  },

};
</script>

<style lang="less" scoped>
/* 头部导航 */
.home-container{
  max-width: 1200px;
  margin: 0 auto;
  /* display: flex; */
  align-items: center;
  height: 60px;
  background-color: #fff;
  .home-modules{
    float: left;
    height: 60px;
  }
  .homenav-container{
    float: right;
    .top-menu {
      border-bottom: none;
    }

    .el-menu-item {
      padding: 0; /* 移除默认内边距 */
      border-bottom: none;
    }

    .menu-item-content {
      padding: 0px 20px;
      border-top: 4px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      color: #333;
    }

    .el-menu-item.is-active .menu-item-content {
      line-height: 60px;
      border-top-color: #09b9e2; 
      border-left-color: #e9e9e9;
      border-right-color: #e9e9e9; 
      border-bottom: none;
      color: #09b9e2;
    }
  }
}
/* 轮播图 */
.slideshow {
  /deep/ .el-carousel__indicators { // 指示灯位置
  // left: unset;
  // transform: unset;
  // right: 2%;
  bottom: 10%;
}
/deep/ .el-carousel__button { // 默认按钮的颜色大小等
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 20px;
  background: #FFFFFF;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button { // 选中图片的颜色
  width: 36px;
  background: #09b9e2;
  opacity: 1;
}
}
// 解决方案1
.solution-fanan{
  background-image: url('../assets/images/beijing.jpg');
  padding-bottom: 80px;
  .solution-babel{
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    .solution-lanindex{
      display: block;
      margin: 0 auto;
    }
    .solution-wenzi p{
      text-align: center;
      color: #969696;
    }
    .solution-reqtre{
      height: 211px;
      position: relative;
      background-image: url('../assets/images/babeiyd1.png');
      background-size: 1200px 211px;
      .solution-asspng{
        position: absolute;
        top: 45px;
        left: 150px;
        width: 110px;
        height: 100px;
      }
      .solution-bajei{
        position: absolute;
        top: 45px;
        left: 414px;
        width: 110px;
        height: 100px;
      }
      .solution-ponen{
        position: absolute;
        top: 45px;
        left: 676px;
        width: 110px;
        height: 100px;
      }
      .solution-rouner{
        position: absolute;
        top: 45px;
        right: 147px;
        width: 110px;
        height: 100px;
      }
      .image-container:hover + img {
        display: block;
      }

    }
    .solution-reqtre img{
      max-width: 1200px;
      margin: 0 auto;
    }
    .solutionconlock{
      margin-top: 30px;
      margin-left: 50px;
      .conlock-login{
        position: relative;
        p{
          font-size: 14px;
          color: #999;
        }
        .conlock-ribje{
          position: absolute;
          top: 10px;
          right: 50px;
          width: 400px;
          height: 250px;
        }
        .circle-buttons{
          margin-top: 80px;
          .circle{
            width: 50px;
            height: 50px;
            border: 1px solid #000;
            border-radius: 50%;
            text-align: center;
            line-height: 50px;
            margin-right: 10px;
          }
          .circle:hover {
              border: 1px solid #09b9e2;
              color: #09b9e2;
          }
        }
        
      }
    }
  }
}
// 关于我们
.bugythio-fsedeo{
  height: 600px;
  background-image: url('../assets/images/haokan.jpg');
  background-size: cover; 
  background-position: center;
  .bugythio-bodetow{
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
  }
  .bugythio-index{
    width: 15%;
    display: block;
    margin: 0 auto;
  }
  .bugythio-wenzi{
    margin-top: 60px;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
}
// 底部
.footer{
  height: 60px;
  background-color: #323232;
  text-align: center;
  line-height: 60px;
  .footer-dugstr{
    padding: 4px 20px;
    font-size: 14px;
    color: #fff;
  }
}
</style>
